import React from 'react'

function Companies({data}) {
  return (
    <div className="second">
                    {/* <div className="images-Div-1"> */}
                        <img src={data.OrrProtection.childImageSharp.fluid.src} alt="Orr Protection" />
                        <img src={data.Averti.childImageSharp.fluid.src} alt='Averti' />
                        <img src={data.HP.childImageSharp.fluid.src} alt="HP" />
                    {/* </div> */}
                    {/* <div className="images-Div-2"> */}
                        
                        
                        <img src={data.evolvedOffice.childImageSharp.fluid.src} alt='evolvedOffice' className='evolvedOffice_img'/>
                        <img src={data.NetAtlantic.childImageSharp.fluid.src} alt="Net Atlantic" className='net_atlantic'/>
                        <img src={data.ImesOman.childImageSharp.fluid.src} alt='Times of Oman' />
                        <img src={data.Borjan.childImageSharp.fluid.src} alt='Borjan' />
                        <img src={data.SuiNorth.childImageSharp.fluid.src} alt='Sui' />
                        <img src={data.OmanDataPark.childImageSharp.fluid.src} alt='Oman Data Park' />
                        <img src={data.ZiePie.childImageSharp.fluid.src} alt='ZiePie' />
                    {/* </div> */}
                    {/* <div className="images-Div-3"> */}
                        
                    {/* </div> */}
                </div>
  )
}

export default Companies