import { Link } from 'gatsby'
import React from 'react'


function Offering_Cont_2({headingText,descriptionText,offeringIcon,rotateCont_1,imageScreenText,inverse,buttonLink}) {
  return (
    <>
    <div style={{position:'relative',}} className={`offering-div`} >
    {/* <svg width="440" height="440" viewBox="0 0 491 491" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${rotateCont_1?'rotateDiv':''}`}> */}
    <svg style={{width:'100%' ,height:'100%', rotate:`${inverse&&'180deg'}`}} viewBox="0 0 586 419" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M217.461 34.6248C257.201 -10.6208 327.623 -10.7368 367.512 34.3776L559.853 251.918C616.872 316.408 571.183 418.015 485.101 418.157L101.136 418.789C15.0539 418.931 -30.9696 317.475 25.837 252.798L217.461 34.6248Z" fill="white"/>
  <path d="M217.837 34.9548C257.378 -10.0646 327.448 -10.1801 367.137 34.7088L559.479 252.249C616.213 316.417 570.752 417.516 485.101 417.657L101.135 418.289C15.4835 418.43 -30.3099 317.481 26.2127 253.128L217.837 34.9548Z" stroke="#1B2D8E" stroke-opacity="0.5"/>
</svg>
    <div className={`offering-div-content `} style={{justifyContent:`${inverse?'start':'center'}`,paddingBlockStart:`${inverse&&'0px'}`}}>
    <div className={`icon-div ${rotateCont_1?'ERP_icon':""} ${(inverse)?"icon-div-height":''}`}>
      <img src={offeringIcon} alt={offeringIcon} className={`offering-icon ${rotateCont_1?'offering-mid-icon':''}`} style={{marginTop:`${offeringIcon&&"0"}`,height:`${(inverse)&&"100%"}`}}/>
      {/* {imageScreenText&&<p className={`${imageScreenText?'offering-Erp-text':''} offering-icon-text`} >{imageScreenText}</p>} */}
      {imageScreenText&&<img className='offering-icon-text' src={imageScreenText}/>}
      </div>
      <div>
      <h3 className="offering-heading-text" style={{marginBottom:`${headingText&&"0"}`}}>{headingText}</h3>
        <p className="offering-description-text" style={{width:`${inverse&&'60%'}`}}>{descriptionText}</p>
        <button className="offering-button"><Link to={buttonLink}>Explore More</Link></button>
      </div>
    </div>
  </div>
    </>
  )
}

export default Offering_Cont_2