import React, { useState, useEffect } from "react"
import { Typography } from "antd"
import { Animated } from "react-animated-css"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useRecoilState } from "recoil"
import counterState from "../services/counterAtom"
import video from "../images/Landingpageheader.mp4"
const textArray = [
  ["Custom Web Application", "Design & Development"],
  ["Enterprise Solutions", "Powered by Odoo"],
  ["Tailored Mobile App Development", "  to Suit Your Requirements"],
  ["Empowering Your Business", "Through BI Solutions"],
  ["Achieving Success Together", "Through Collaborative Efforts"],
]

const subTextArray = [
  [
    "We take pride in crafting digital solutions that seamlessly align",
    "with your objectives, helps you focus on the core business.",
  ],
  [
    "Thinking about online selling? We have the perfect e-commerce",
    " solution that allows effectively managing your inventory and sales.",
  ],
  [
    "Do you have an idea for a mobile app or want to bring operations",
    "to your fingertips? We build specialized cross-platform mobile applications.",
  ],
  [
    "Ensuring the quality of your software is the key to smooth",
    " operations. We have experts who can help you manage your QA activities and perform manual or automatic testing.",
  ],
  [
    "We consider our customers as our partners. We work with them to",
    " provide/enhance software solutions that allow them to focus on the business.",
  ],
]

const backgroundColorsArray = [
  {
    lightColor: "rgb(17 36 138)",
    darkColor: "#0d1b67",
    fontColor: "#ffffff",
    buttonClass: "white",
  },
  {
    lightColor: "#F0CD17",
    darkColor: "rgb(240 205 23)",
    fontColor: "#ffffff",
    buttonClass: "black",
  },
  {
    lightColor: "rgb(17 36 138)",
    darkColor: "#0d1b67",
    fontColor: "#ffffff",
    buttonClass: "white",
  },
  {
    lightColor: "#F0CD17",
    darkColor: "rgb(240 205 23)",
    fontColor: "#ffffff",
    buttonClass: "black",
  },
  {
    lightColor: "rgb(17 36 138)",
    darkColor: "#0d1b67",
    fontColor: "#ffffff",
    buttonClass: "white",
  },
]

const getArtWorkStyles = [
  { marginTop: "18vh" },
  {
    transform: "scale(1.6)",
    marginTop: "20vh",
  },
  { marginTop: "15vh" },
  {},
  {},
]

const HomePageIntro = ({ data }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [count, setCount] = useRecoilState(counterState)
  const { Title } = Typography

  useEffect(() => {
    const interval = setInterval(() => setIsVisible(false), 6000)
    const secondInterval = setInterval(() => {
      if (count < 4) {
        setCount(count + 1)
      } else {
        setCount(0)
      }
      setIsVisible(true)
    }, 6300)
    return () => {
      clearInterval(interval)
      clearInterval(secondInterval)
    }
  }, [count,setCount])

  const [videoLoaded, setVideoLoaded] = useState(false)

  const handleVideoLoad = () => {
    setVideoLoaded(true)
  }
  return (
    <Animated
      className="text-and-image-container"
      style={{
        position: "relative",
        zIndex: 0,
        objectFit: "cover",
      }}
    >
      {!videoLoaded && (
        <Img
          fluid={data.thumbnail.childImageSharp.fluid}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "106%",
            objectFit: "cover",
            zIndex: -2,
          }}
        />
      )}

      <video
        autoPlay
        loop
        muted
        onLoadedData={handleVideoLoad}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "106%",
          objectFit: "cover",
          zIndex: -2,
        }}
      >
        <source src={video} type="video/mp4" />
      </video>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "103%",
          zIndex: -2,
        }}
      >
        <div className="logo">
          <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
        </div>
        <div className="animated-div">
          <Animated
            animationIn="flipInX"
            animationInDuration={500}
            animationOut="flipOutX"
            animationInDelay={700}
            animationOutDuration={300}
            isVisible={isVisible}
          >
            <Title
              style={{ color: backgroundColorsArray[count].fontColor }}
              className="title"
            >
              {textArray[count][0]}
            </Title>
          </Animated>
          <Animated
            animationIn="flipInX"
            animationInDuration={500}
            animationOut="flipOutX"
            animationInDelay={700}
            animationOutDuration={300}
            isVisible={isVisible}
          >
            <Title
              style={{
                color: backgroundColorsArray[count].fontColor,
                marginTop: "-33px",
              }}
              className="title"
            >
              {textArray[count][1]}
            </Title>
          </Animated>

          <Animated
            animationIn="flipInX"
            animationInDuration={500}
            animationOut="flipOutX"
            animationInDelay={700}
            animationOutDuration={300}
            isVisible={isVisible}
          >
            <p
              style={{
                color: backgroundColorsArray[count].fontColor,
                marginBottom: "9px",
              }}
              className="peragraph"
            >
              {subTextArray[count][0]}
            </p>
          </Animated>
          <Animated
            animationIn="flipInX"
            animationInDuration={500}
            animationOut="flipOutX"
            animationInDelay={700}
            animationOutDuration={300}
            isVisible={isVisible}
          >
            <p
              style={{ color: backgroundColorsArray[count].fontColor }}
              className="peragraph"
            >
              {subTextArray[count][1]}
            </p>
          </Animated>
        </div>
        <div className="ArrowImage">
          <Link to="/contact">
            <p className="p-color-about-us">Let’s Connect</p>
            <Img fluid={data.arrowImageWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div
          className={`art-work ${count === 1 ? "second-artwork" : ""}`}
          style={getArtWorkStyles[count]}
        ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "103%",
            backgroundColor: "#1B2D8E",
            zIndex: -2,
            opacity: 0.7,
          }}
        ></div>
      </div>
    </Animated>
  )
}

export default HomePageIntro
