import React, { useState, memo } from "react"
import behindImageSmall from "../images/Group 495@2x.png"
import EvolvedOff from '../images/Evolved_Office_new_logo_side_final_web.png'
import OmanData from '../images/Oman_data_park.png'
import NetAtlantic from '../images/NetAtlantic_color.png'
import OrrProtection from "../images/orrprotection.svg"
import HP from "../images/hp.svg"

const HowWeWork = ({ data }) => {
  const [isElementInView, setisElementInView] = useState(false)
  if (!isElementInView && typeof document !== "undefined") {
    document.addEventListener("scroll", () => {
      if (
        document.querySelector(".process-within-home-page") &&
        document
          .querySelector(".process-within-home-page")
          .getBoundingClientRect().top <= window.innerHeight
      ) {
        setisElementInView(true)
      }
    })
  }

  return (
    <div style={{position:"relative"}}>
    <div className="process-within-home-page">
      <div className="heading-container">
        <h1 className="heading">Why Work with us?</h1>
      </div>
      <div className="process-steps-wrapper">
        <div className="our-working">
          <div >
            <h3 className="title">Punctual & <br/> Cost-Effective</h3></div>
          <div className="description">
          We employ a well-established agile methodology
          to ensure the swift and efficient delivery of
          your software project
          </div>
        </div>
        <div className="our-working">
          <div >
          <h3 className="title">Tailored to Your <br/>Business Needs</h3>
          </div>
          <div className="description">
          We have earned the trust of startups,
          small businesses, and large
          enterprises alike
          </div>
        </div>
        <div className="our-working support">
          <div >
          <h3 className="title">Dedicated <br/>Support</h3>
          </div>
          <div className="description">
          A 30-day warranty and round-the-clock support,
          backed by clearly defined SLAs to ensure 
          the uninterrupted operation of your software
          </div>
        </div>
      </div>
      <div className="heading-container">
        <span className="trusted-heading">Trusted By</span>
        <div className="trusted-companies">
        <img src={EvolvedOff} alt='Evolved office'  className="howWeWorkCompany trusted-companies-1" />
        <img src={NetAtlantic} alt="NetAtlantic" className="howWeWorkCompany trusted-companies-2" />
        <img src={OrrProtection} alt='OrrProtection'  className="howWeWorkCompany orr_image trusted-companies-3"/>
        <img src={HP} alt="hushPuppies" className="howWeWorkCompany husppupies_image trusted-companies-4"/>
        <img src={OmanData} alt='OmanData'  className="howWeWorkCompany omanData_image  trusted-companies-5"/>
        </div>
      </div>
    </div>
    <div className="behindimagediv">
     <img
       className="behindImageSmall"
       src={behindImageSmall}
       alt="yellow-triangle"
     />
     <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
     </div>
     </div>
  )
}

export default memo(HowWeWork)
