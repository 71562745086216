import { Link } from 'gatsby'
import React from 'react'


function OfferingCont1({headingText,descriptionText,offeringIcon,rotateCont_1,imageScreenText,inverse,inverseRotate,buttonLink}) {
  return (
    <>
    <div style={{position:'relative',flexDirection:`${inverse?'column-reverse':''}`}} className={`offering-div`} >
    <svg style={{width:'100%',rotate:`${(inverse&&'180deg')||(inverseRotate&&'90deg')}`,transform: `${inverse&&'scaleX(-1)'}`}}  viewBox="0 0 491 491" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${rotateCont_1?'rotateDiv':inverseRotate&&'inverse_rotate'}`}>
    {/* <clipPath id="wavy-clip-path" clipPathUnits="objectBoundingBox"> */}
    <path d="M0.979568 116.291C-7.09575 56.615 39.0182 3.39067 99.2358 2.88449L389.603 0.443653C475.682 -0.279902 522.391 100.863 466.022 165.922L214.594 456.118C158.226 521.177 51.462 489.35 39.9186 404.046L0.979568 116.291Z" fill="white"/>
    <path d="M1.47505 116.224C-6.55989 56.8464 39.3235 3.8881 99.24 3.38447L389.607 0.943626C475.256 0.223681 521.731 100.86 465.644 165.595L214.216 455.791C158.13 520.525 51.8998 488.857 40.4141 403.979L1.47505 116.224Z" stroke="#1B2D8E" stroke-opacity="0.5"/>
    {/* </clipPath> */}
    </svg>
    <div className="offering-div-content" style={{flexDirection:`${inverseRotate?'column-reverse':''} ${inverse?'column-reverse':''}`}}>
      <div>
      <h3 className="offering-heading-text" style={{width:`${inverse&&'70%'}`, paddingRight:`${inverse?'25px':""}`}}>{headingText}</h3>
        <p className="offering-description-text" style={{alignSelf:`${rotateCont_1||inverseRotate?"end":"start"}`}}>{descriptionText}</p>
        <button className="offering-button"><Link to={buttonLink}>Explore More</Link></button>
      </div>
      <div className={`icon-div ${(rotateCont_1||inverseRotate)&&"iconPlacement"} ${(inverse||inverseRotate)&&"icon-div-height"}`} >
      <img src={offeringIcon} alt={offeringIcon} className={`offering-icon verically-align-div ${rotateCont_1?'offering-icon-right':inverseRotate?"offering-icon-right":""}`}  />
      {imageScreenText&&<p className='offering-icon-text'>{imageScreenText}</p>}
      </div>
    </div>
  </div>
    </>
  )
}

export default OfferingCont1