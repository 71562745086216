import React, { useState, memo, useEffect } from "react"
import { Typography } from "antd"

import JSIcon from "../images/javascript.svg"
import TSIcon from "../images/typescript.svg"
import Html5 from "../images/html5.svg"
import Css3 from "../images/css3.svg"
import ReactIcon from "../images/react.svg"
import Sass from "../images/sass.svg"
import Bootstrap from "../images/bootstrap.svg"
import CSharp from "../images/csharp.svg"
import Python from "../images/python.svg"
import Django from "../images/django.svg"
import NodeJS from "../images/nodejs.svg"
import MySql from "../images/mysql.svg"

import NetCore from "../images/netcore.svg"
import ExpressJS from "../images/ExpressJS.svg"
import GraphQL from "../images/GraphQL.svg"
import API from "../images/api.svg"
import Android from "../images/android.svg"
import IOS from "../images/ios.svg"
import Dart from "../images/dart.svg"
import Flutter from "../images/flutter.svg"
import ReactNative from "../images/react-native.svg"
import MongoDB from "../images/mongodb.svg"
import PostreSQL from "../images/posgresql.svg"
import MSSQL from "../images/mssql.svg"
import Oracle from "../images/oracle.svg"
import AWS from "../images/aws.svg"
import AWSLambda from "../images/aws-lambda.svg"
import Azure from "../images/azure.svg"
import AzureDataFactory from "../images/azuredatafactory.svg"
import Odoo from "../images/odoo.svg"
import MicrosoftDynamics from "../images/msdynamics.svg"

import { technologies } from "../data"
import IconsAdded from "./IconsAdded"
const { Title } = Typography

const getIconAndText = tech => {
  const icons = {
    "Frontend Web Development": [
      { iconImage: Html5, iconText: "HTML5" },
      { iconImage: Css3, iconText: "CSS 3" },
      { iconImage: Sass, iconText: "Sass" },
      { iconImage: Bootstrap, iconText: "Bootstrap" },
      { iconImage: JSIcon, iconText: "Javascript" },
      { iconImage: TSIcon, iconText: "Typescript" },
      { iconImage: ReactIcon, iconText: "React JS" },
    ],
    "Backend Development": [
      { iconImage: CSharp, iconText: "C Sharp" },
      { iconImage: Python, iconText: "Python" },
      { iconImage: NetCore, iconText: "Asp .net" },
      { iconImage: Django, iconText: "Django" },
      { iconImage: NodeJS, iconText: "NodeJS" },
      { iconImage: ExpressJS, iconText: "Express JS" },
      { iconImage: GraphQL, iconText: "GraphQL" },
      { iconImage: API, iconText: "REST API's" },
    ],
    "Mobile App Development": [
      { iconImage: Android, iconText: "Android" },
      { iconImage: IOS, iconText: "iOS" },
      { iconImage: ReactNative, iconText: "React Native" },
      { iconImage: Dart, iconText: "Dart" },
      { iconImage: Flutter, iconText: "Flutter" },
    ],
    Databases: [
      { iconImage: MSSQL, iconText: "MS SQL Server" },
      { iconImage: MySql, iconText: "MySQL" },
      { iconImage: PostreSQL, iconText: "PostgreSQL" },
      { iconImage: Oracle, iconText: "Oracle" },
      { iconImage: MongoDB, iconText: "MongoDB" },
    ],
    "ERP Solutions": [
      { iconImage: MicrosoftDynamics, iconText: "Microsoft D365" },
      { iconImage: Odoo, iconText: "Odoo" },
    ],
    "Infra & DevOps": [
      { iconImage: AWS, iconText: "AWS" },
      { iconImage: Azure, iconText: "Microsoft Azure" },
      { iconImage: AWS, iconText: "Cloud RDS" },
      { iconImage: AWSLambda, iconText: "Lambda" },
      { iconImage: AzureDataFactory, iconText: "Azure Data Factory" },
      { iconImage: AWS, iconText: "AWS S3" },
    ],
  }

  return icons[tech] || []
}
const Technologies = () => {
  const [selectedTech, setSelectedTech] = useState("Frontend Web Development")
  const [selected, setSelected] = useState(true)
  const [selectedButton, setSelectedButton] = useState("")
  const numButtons = technologies.length
  const [activeButton, setActiveButton] = useState(-1)

  useEffect(() => {
    setTimeout(() => {
      setActiveButton(0)
    }, 0)
  }, [])

  useEffect(() => {
    if (selected) {
      const interval = setInterval(() => {
        setActiveButton(prev => (prev + 1) % numButtons)
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [selected, numButtons])

  useEffect(() => {
    const tech = technologies[activeButton]
    setSelectedTech(tech)
  }, [activeButton])
  console.log(selectedTech, activeButton)
  return (
    <div className="technologies-within-home-page position-relative">
      <div className="news-Heading-container">
        <div className="heading-postion">
          <h1 className="news-heading">
            Let us Build your Solution with any of the Technologies
          </h1>
        </div>
      </div>
      <div className="technologies_animation_div">
        <div className="technologies_buttons">
          {technologies.map((tech, index) => (
            <>
              <button
                key={tech}
                className={`technologies_button ${
                  index === activeButton && selected
                    ? "active_button"
                    : selectedButton === tech && !selected
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  setSelectedTech(tech)
                  setSelectedButton(tech)
                  setSelected(false)
                }}
              >
                {tech}
              </button>
              {(index === activeButton && selected||selectedButton === tech && !selected) && (
                <div className="mobile_technologies_icons_div">
                  {getIconAndText(selectedTech)
                    .reduce((result, item, index) => {
                      const chunkIndex = Math.floor(
                        index /
                          (selectedTech === "Frontend Web Development" &&
                          getIconAndText(selectedTech).length > 7
                            ? 3
                            : 2)
                      );
                      if (!result[chunkIndex]) result[chunkIndex] = [];
                      result[chunkIndex].push(item);
                      return result;
                    }, [])
                    .map((chunk, chunkIndex) =>
                      chunk.map((t, colIndex) => (
                        <div key={`${chunkIndex}-${colIndex}`} className="technology_icon_div">
                          <img
                            src={t.iconImage}
                            alt={t.iconText}
                            className={`technologyIcon ${t.iconText=== "Microsoft D365"?'dynamics_icon':t.iconText==='Express JS'?'express_icon':''}`}
                            width="300" height="200"
                          />
                          <p style={{ color: "white" }} className="technologyText">
                            {t.iconText}
                          </p>
                        </div>
                      ))
                    )}
                </div>
              )}
             </>
          ))}
        </div>

        <div className="technology_icons_div">
          {getIconAndText(selectedTech)
            .reduce((result, item, index) => {
              const chunkIndex = Math.floor(
                index /
                  (selectedTech === "Frontend Web Development" &&
                  getIconAndText(selectedTech).length > 7
                    ? 3
                    : 2)
              )
              if (!result[chunkIndex]) result[chunkIndex] = []
              result[chunkIndex].push(item)
              return result
            }, [])
            .map((chunk) =>
              chunk.map((t, colIndex) => (
                <div key={colIndex} 
                className={`technology_icon_div ${activeButton || selected ? 'show' : ''}`}
                >
                  <img
                    src={t.iconImage}
                    alt={t.iconText}
                    className="technologyIcon"
                  />
                  <p style={{ color: "white" }} className="technologyText">
                    {t.iconText}
                  </p>
                </div>
              ))
            )}

          
        </div>
      </div>
      <IconsAdded/>
    </div>
  )
}

export default memo(Technologies)
