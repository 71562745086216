import React, { memo } from 'react'
import Img from "gatsby-image"
const Registration = ({ data }) => {
    return (
        <div className='registartion'>
            <div className='registartion-container'>
                <div className='first'>
                    <div className='Rectangle'>
                        <Img fluid={data.Rectangle.childImageSharp.fluid} />
                    </div>
                    <div className='pseb'>
                        <Img fluid={data.pseb.childImageSharp.fluid} />
                    </div>
                </div>
                <div className='second'>
                    <p>
                        Together, as a team with unwavering commitment,  we strive to place Visiomate at the forefront of the  global tech industry. Our ambitious vision is to  inspire change and set new benchmarks that will  redefine the status quo.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default memo(Registration)
