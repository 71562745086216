import React, { memo} from "react"
import OfferingCont1 from "./Offering_Cont_1"
import EcommerceIcon from "../images/Ecom-01 1.png";
import CRMIcon from "../images/CRM-01 1.png";
import ERPIcon from "../images/ERP-01 2.png";
import YellowPolygon from "../images/Polygon 14.png";
import WebDevIcon from "../images/WebDev-01 1.png";
import MobileIcon from "../images/Mobile-01 1.png";
import NoCodeIcon from "../images/Nocode-01 1.png";
import ConsultIcon from "../images/Consult-01 1.png";
import BluePolygon from "../images/Group 520.png";
import AIIcon from "../images/AI-01 1.png";
import OfferingCont2 from "./Offering_Cont_2"



const Services = ({ data }) => {
  return (
    <>
      <div className="OurStrongestCompetencies">
        <div className="header">
          <h1> Explore Our Offering</h1>
        </div>
        
        <div className="offering-row-div">
          <div className="offering-wrappimg-container" id="section-one-left-shape">
            <OfferingCont1 headingText='E-commerce Solution and Integration' descriptionText='Launch your online store with our robust e-commerce solution and seamless integrations. Drive sales, optimize operations, and enhance customer experience with our comprehensive platform.' offeringIcon={EcommerceIcon} buttonLink={'/services/e_commercesolution'}/>
          </div>
          <div className="offering-wrapping-mid-container" id="offering-wrapping-mid-container-1">
            <OfferingCont2 offeringIcon={ERPIcon} descriptionText='Select an ERP solution that will optimize your workflow and create a resource integration that will fit your specific requirements and demands to meet the expansion of your business more quickly.' rotateCont_1={true} headingText='ERP Solution and Integration' buttonLink={'/services/erpsolution'}/>
          </div>
          <div className="offering-wrappimg-container" id="section-one-right-shape">
            <OfferingCont1 headingText='CRM Solution and Integration' descriptionText='Streamline your business with our CRM solution and integration services. Improve customer relationships, boost sales, and enhance productivity through automation and data-driven insights.' offeringIcon={CRMIcon} rotateCont_1={true} buttonLink={'/services/crmsolution'}/>
          </div>
          <img src={YellowPolygon} alt={'YellowPolygon'} className="yellowPolygon-icon"/>
          <img src={YellowPolygon} alt={'YellowPolygon'} className="yellowPolygon-icon-rotate"/>
        </div>
        <div className="offering-row-div" id="mobile-view-offering-row-div">
        <img src={YellowPolygon} alt={'YellowPolygon'} className="yellowPolygon-icon" id="optional-polygon-one"/>
          <div className="offering-wrappimg-container" id="section-second-left-shape">
            <OfferingCont1 headingText='Mobile App Development and Integration' descriptionText='Reach your audience on any device with our expert mobile app development and integration services. We build native and cross-platform apps with seamless functionality.' offeringIcon={MobileIcon} inverse={true} buttonLink={'/services/mobiledevelopment'}/>
          </div>
          <img src={YellowPolygon} alt={'YellowPolygon'} className="yellowPolygon-icon" id="optional-polygon-two"/>
          <div  className="offering-wrapping-mid-container" id="offering-wrapping-mid-container-2">
          <OfferingCont2 offeringIcon={WebDevIcon} descriptionText='Bring your vision to life with our custom web development and integration services. We build high-performing, scalable websites tailored to your unique needs.' rotateCont_1={true} headingText='Custom Web Development and Integration' inverse={true} buttonLink={'/services/webdevelopment'}/>
          </div>
          <img src={YellowPolygon} alt={'YellowPolygon'} className="yellowPolygon-icon" id="optional-polygon-three"/>
          <div className="offering-wrappimg-container" id="section-second-right-shape">
            <OfferingCont1 headingText='AI Integration' descriptionText='Unlock the power of AI with our cutting-edge development and integration services. We build intelligent solutions that automate tasks, improve decision-making, and drive innovation.' offeringIcon={AIIcon} inverseRotate={true} buttonLink={'/services/aisolution'}/>
          </div>
            
        </div>
        <div className="last-section-div">
        <img src={YellowPolygon} alt={'YellowPolygon'} className="yellow-mid-icon"/>
          <div className="offering-last-div-sec">
            <OfferingCont1 headingText='No-Code App Development' descriptionText='Build powerful apps without coding! Our no-code app development team empowers you to create custom solutions quickly and easily.  Start building your dream app today.' offeringIcon={NoCodeIcon} buttonLink={'/services/nocodesolution'}/>
            </div>
          <div className="offering-last-div-sec">
            <OfferingCont1 headingText='Software Consulting Service' descriptionText='Optimize your software strategy with expert consultancy. We analyze your needs, recommend solutions, and guide your digital transformation. Contact us for a free consultation.' offeringIcon={ConsultIcon} rotateCont_1={true} buttonLink={'/services/softwareconsultancy'}/>
          </div>
        </div>
      </div>
      <div className="ending-polygon-div">
      <img src={BluePolygon} alt="BluePolygon" className="left-blue-poly-icon"/>
      <img src={BluePolygon} alt="BluePolygon" className="right-blue-poly-icon"/>
      </div>
    </>
  )
}

export default memo(Services)
