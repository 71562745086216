import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Services from "../components/Services"
import HowWeWork from "../components/HowWeWork"
import Portfolio from "../components/Portfolio"
import Technologies from "../components/Technologies"
import Clients from "../components/Clients"
import Reviews from "../components/Reviews"
import Registration from "../components/Registration"
import News from "../components/News"
import HomePageIntro from "../components/HomePageIntro"
import { projects } from "../data"

import "../styles/home.scss"
import "../styles/animate.css"
const Home = ({ data }) => {
  return (
    <Layout
      className="home-page-intro"
      data={data}
      style={{
        background: `white`,
      }}
      pageTitle={"Visiomate - Software Solution Provider"}
      descrip = {"Streamline your business with our expertise in web & mobile app development and Odoo ERP implementation. We deliver customized solutions for growth of our customers."}
    >
      <div>
        <HomePageIntro data={data} />
      </div>
      <HowWeWork data={data} />
      <Services data={data} />
      <Portfolio items={projects.slice(0, 8)} data={data} />
      <Technologies />
      <Clients data={data} />
      <Reviews data={data} />
      <Registration data={data} />
      <News isAbout={false} />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query Images {
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SNGPL: file(relativePath: { eq: "mobile/SNGPLApp-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SBP: file(relativePath: { eq: "mobile/statebankapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISO: file(relativePath: { eq: "iso.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    NetAtlantic: file(relativePath: { eq: "about/netatlantic.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LMS: file(relativePath: { eq: "LMS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    EMS: file(relativePath: { eq: "EMS/ems_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
      Averti: file(relativePath: { eq: "averti 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BanuMukhtar: file(relativePath: { eq: "banomukhtar 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    NetAtlantic: file(relativePath: { eq: "about/netatlantic.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    OmanDataPark: file(relativePath: { eq: "image 7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImesOman: file(relativePath: { eq: "image 8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Mtech: file(relativePath: { eq: "logo 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    OrrProtection: file(relativePath: { eq: "orr 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SoharPort: file(relativePath: { eq: "soharport 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SuiNorth: file(relativePath: { eq: "image 4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ZiePie: file(relativePath: { eq: "ziepie 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    evolvedOffice: file(relativePath: { eq: "Evolved_Office_new_logo_side_final_web.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HP: file(relativePath: { eq: "hp 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    KM: file(relativePath: { eq: "image 5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Borjan: file(relativePath: { eq: "image 3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    DistributionD365: file(
      relativePath: { eq: "DistributionD365/dist_top_header.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PowerBI: file(relativePath: { eq: "power-BI.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Azure: file(relativePath: { eq: "azure.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/viztos_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Bigjerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    UmrahShuttle: file(relativePath: { eq: "mobile/umrahshuttleapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Visioedge: file(relativePath: { eq: "caseStudies/visioedgeheader.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrowImageWhite: file(relativePath: { eq: "Mask group (6).png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webColorIcon:file(relativePath: { eq: "web_color1-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileColorIcon:file(relativePath: { eq: "mobile/mobile_color1-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    biColorIcon:file(relativePath: { eq: "BI_color1-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    d365ColorIcon:file(relativePath: { eq: "D365/ERP_color1-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thumbnail: file(relativePath: { eq: "Screenshot (62).png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    UMTVisiomate: file(relativePath: { eq: "lifeatvisiomate/UMT_Visiomate3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    umrahshuttleapp: file(relativePath: { eq: "mobile/umrahshuttleapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viztosForMenu: file(relativePath: { eq: "news/viztos.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    learningcircle: file(relativePath: { eq: "news/learning_circle.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CloseIcon: file(relativePath: { eq: "Rectangle86.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twitter: file(relativePath: { eq: "twitter_bird.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    youtube: file(relativePath: { eq: "youtube.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrow: file(relativePath: { eq: "arrow.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    behindImageSmall: file(relativePath: { eq: "Maskgroup1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    behindImageLarg: file(relativePath: { eq: "Maskgroup.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webcaseDiv1: file(relativePath: { eq: "EMS/ems_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webcaseDiv2: file(relativePath: { eq: "Custom/Diversepro-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobilecaseDiv1: file(relativePath: { eq: "mobile/umrahshuttleapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobilecaseDiv2: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    erpcaseDiv1: file(relativePath: { eq: "DistributionD365/dist_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    erpcaseDiv2: file(relativePath: { eq: "D365/xstackintegration1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bicaseDiv1: file(relativePath: { eq: "BI/powerbidashboard.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bicaseDiv2: file(relativePath: { eq: "EMS/ems_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pseb: file(relativePath: { eq: "logo-pseb 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Rectangle: file(relativePath: { eq: "Rectangle.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImg: file(relativePath: { eq: "Maskgroup4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BackImg: file(relativePath: { eq: "MaskgroupYellow.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
