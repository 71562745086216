import React, { memo } from "react"
import Img from "gatsby-image"
import review from "../images/review.svg"
import IconsAdded from "./IconsAdded"

const Reviews = ({ data }) => {

  return (
    <div className="reviews-within-home-page position-relative">
      <div className="news-Heading-container">
        <div className="heading-postion">
          <h1 className="heading-text">What our Customers say? </h1>
        </div>
      </div>
      <div class="container">
        <div class="First">
          <h6 className="h6">Sean Murphy</h6>

          <p className="h6_2ndLine">
            Director, Application & Cloud Development at ORR Protection USA
          </p>
          <img
            src={review}
            alt="review"
            className="review-img change-my-color"
          ></img>
          <p className="reviews-second-p">
            We're incredibly impressed with Visiomate's services. Their
            knowledge and experience of Odoo, .NET development, and third-party
            integration has revolutionized our system. Their professionalism,
            expertise, and dedication were evident throughout the project. We
            highly recommend their services to anyone seeking reliable
            development solutions.
          </p>
        </div>
        <div class="Second">
          <h6 className="h6">Mian Arif Hussain</h6>

          <p className="h6_2ndLine">CEO at Hummak Engineering Pakistan</p>
          <p className="reviews-second-p">
            Our AX 2012 system underwent a remarkable transformation with the
            help of Visiomate. Their expertise and dedication delivered
            outstanding results, significantly improving our operations. Highly
            recommended!{" "}
          </p>
        </div>
        <div class="Third">
          <h6 className="h6">Syed Waqas</h6>
          <p className="h6_2ndLine ">CEO at ZiePie USA</p>
          <p className="reviews-second-p">
            Working closely with ZiePie across multiple industries, including
            Education, Travel, and Power BI projects, has been an invaluable
            experience. Their expertise spans diverse sectors, and their ability
            to deliver exceptional results remains consistent across each
            project. ZiePie's professionalism, adaptability, and commitment to
            excellence make them a trusted partner in achieving our goals across
            various industries. We highly recommend ZiePie to anyone seeking
            top-tier solutions tailored to their specific needs.
          </p>
          <img src={review} alt="review" className="review-img-third"></img>
        </div>

        <div class="Fourth">
          <h6 className="h6">Muzammil Jamil</h6>

          <p className="h6_2ndLine">Manager IT at Sapphire Pakistan</p>
          <p className="reviews-second-p">
          Visiomate's has a very dedicated team that helped complete many projects that enhanced the ERP solution when I was in Hush Puppies. They won my trust, and I would easily suggest them for D365 support work.
          </p>
        </div>
        <div class="Fifth">
          <h6 className="h6">Jason Lorenti</h6>
          <p className="h6_2ndLine">
            Principle Solution Architect at EvolvedOffice USA
          </p>
          <p className="reviews-second-p">
          EvolvedOffice's product underwent significant enhancement thanks to its close collaboration with Visiomate's team. Their expertise and dedication brought innovative solutions, driving product improvement. We greatly value our partnership and look forward to achieving more together.
          </p>
        </div>
      </div>
    </div>
  )
}

export default memo(Reviews)
